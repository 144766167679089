import { AuthenticationAppRoutes } from "./AuthenticationAppRoutes";
import { AppLogo } from "./features/app/AppLogo";
// import { AppStoresPush } from "./features/app/AppStoresPush";
import { AssistancePush } from "./features/assistance/AssistancePush";
import { BannersLandscape } from "./features/banners/BannersLandscape";
import { CompanyNav } from "./features/company/CompanyNav";
import { AppendicesNav } from "./features/core/AppendicesNav";

import "./AuthenticationApp.scss";

const classNamePrefix = "AuthenticationApp";

export const AuthenticationApp = () => (
  <div className={classNamePrefix}>
    <header className={`${classNamePrefix}-header`}>
      <div className={`${classNamePrefix}-companyNav`}>
        <CompanyNav />
      </div>
      <div className={`${classNamePrefix}-appLogo`}>
        <AppLogo />
      </div>
    </header>
    <main className={`${classNamePrefix}-main`}>
      <div className={`${classNamePrefix}-routes`}>
        <AuthenticationAppRoutes />
      </div>
      <div className={`${classNamePrefix}-ad`}>
        <BannersLandscape />
      </div>
    </main>
    <footer className={`${classNamePrefix}-footer`}>
      <div>
        <div className={`${classNamePrefix}-more`}>
          <div className={`${classNamePrefix}-assistanceHotlinePush`}>
            <AssistancePush />
          </div>
          {/*<div className={`${classNamePrefix}-appStoresPush`}>
            <AppStoresPush />
          </div>*/}
        </div>
        <div className={`${classNamePrefix}-appendicesNav`}>
          <AppendicesNav />
        </div>
      </div>
    </footer>
  </div>
);
