import { Auth } from "@aws-amplify/auth";
import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { v4 as uuid } from "uuid";

import { baseUrl } from "../baseUrl";

export const privateApi = createApi({
  baseQuery: baseQuery({ baseUrl: `${baseUrl}/mynexity/api` }),
  endpoints: () => ({}),
  keepUnusedDataFor: 30 * 60,
  reducerPath: "privateApi",
  tagTypes: [
    "Contract",
    "CurrentUser",
    "Demand",
    "Eligibility",
    "EServiceGroup",
    "InterlocutorGroup",
    "Lot",
  ],
});

type BaseQueryContext = { baseUrl: string };

function baseQuery(context: BaseQueryContext): BaseQueryFn<
  AxiosRequestConfig & {
    responseHandler?: (response: AxiosResponse) => unknown;
  }
> {
  return async (arg) => {
    try {
      const currentSession = await Auth.currentSession();
      const response = await axios({
        ...arg,
        headers: {
          ...arg.headers,
          Authorization: currentSession.getIdToken().getJwtToken(),
          "X-Correlation-Id": uuid(),
          "X-Source": "monEspaceImmo - Particuliers",
        },
        url: `${context.baseUrl}${arg.url}`,
        withCredentials: true,
      });
      return {
        data: arg.responseHandler
          ? arg.responseHandler(response)
          : response.data,
      };
    } catch (_error) {
      const error = _error as AxiosError;
      return {
        error: {
          data: error.response?.data ?? error.message,
          status: error.response?.status ?? 500,
        },
      };
    }
  };
}
