import { EnvType, envType } from "../config/envType";
import { i18next } from "../config/i18next";
import store from "../store";

declare const window: Window & { dataLayer: any[] };

export type EventDataLayer = {
  eventAction: string;
  eventCategory: string;
  eventLabel: string;
};

function trackEvent(dataLayer: EventDataLayer) {
  window.dataLayer.push({ event: "uaevent", ...dataLayer });
}

export type PageDataLayer = {
  categorie_page: string;
  marche?: string;
  metier?: string;
  nom_page: string;
  virtualpagetitle: string;
};

function trackPage(dataLayer: PageDataLayer) {
  const currentUser = store.getState().auth.currentUser;
  const { id, logged, profile } = currentUser
    ? { id: currentUser.id, logged: "oui", profile: currentUser.profile }
    : { id: "", logged: "non", profile: "" };
  window.dataLayer.push({
    env_site:
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      )
        ? "mobile"
        : "web",
    env_working: envType === EnvType.PRD ? "prod" : "staging",
    event: "virtualpage",
    langue: i18next.language,
    marche: "",
    metier: "",
    univers: "b2c",
    univers_monEspaceImmo: "particuliers",
    user_id: id,
    user_logged: logged,
    user_profile: profile,
    virtualpage_url: dataLayer.nom_page,
    ...dataLayer,
  });
}

export const tracking = { trackEvent, trackPage };
