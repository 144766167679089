import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { v4 as uuid } from "uuid";

import { baseUrl } from "../baseUrl";

export const publicApi = createApi({
  baseQuery: baseQuery({ baseUrl: `${baseUrl}/mynexity/public` }),
  endpoints: () => ({}),
  keepUnusedDataFor: 30 * 60,
  reducerPath: "publicApi",
  tagTypes: [],
});

type BaseQueryContext = { baseUrl: string };

function baseQuery(context: BaseQueryContext): BaseQueryFn<
  AxiosRequestConfig & {
    responseHandler?: (response: AxiosResponse) => unknown;
  }
> {
  return async (arg) => {
    try {
      const response = await axios({
        ...arg,
        headers: {
          ...arg.headers,
          "X-Correlation-Id": uuid(),
          "X-Source": "monEspaceImmo - Particuliers",
        },
        url: `${context.baseUrl}${arg.url}`,
      });
      return {
        data: arg.responseHandler
          ? arg.responseHandler(response)
          : response.data,
      };
    } catch (_error) {
      const error = _error as AxiosError;
      return {
        error: {
          data: error.response?.data ?? error.message,
          status: error.response?.status ?? 500,
        },
      };
    }
  };
}
